.map-overlay {
	width: 100%;
	height: var(--viewport-height);
	position: relative;

	&--map {
		background: rgba(0, 0, 0, 0.6);
		position: relative;
		height: var(
			--viewport-height
		); //have to set for mobile when orientation changes
		overflow: hidden;
		&-inner {
			height: 109% !important; // Hide google things - !important to override Googles inline style
		}
	}

	&--exit {
		@include base-heading;
		color: var(--c-site-accent);
		padding: var(--padding-rythm-sm);
		background: white;
		position: absolute;
		left: var(--padding-rythm-sm);
		z-index: 101;
		top: var(--padding-rythm-sm);
	}

	// &--show-location {
	// 	@include base-heading;
	// 	color: var(--c-site-accent);
	// 	padding: var(--padding-rythm-sm);
	// 	background: white;
	// 	position: absolute;
	// 	left: var(--padding-rythm-sm);
	// 	z-index: 101;
	// 	bottom: var(--padding-rythm-sm);
	// }

	&--legend {
		position: absolute;
		right: var(--padding-rythm-sm);
		z-index: 101;
		top: var(--padding-rythm-sm);
		padding: var(--padding-rythm-sm);
		background: white;

		&-pin + &-pin {
			margin-top: 0.5em;
		}
		&-pin {
			color: black;
			&:before {
				content: "";
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background: var(--c);
				display: inline-block;
				margin-right: 5px;
			}
			&__street-art {
				--c: #3b247d;
			}
			&__festival-wall {
				--c: #e61c6b;
			}
			&__event-venue {
				--c: #00aeef;
			}
			&__info-point {
				--c: #fcaf17;
			}
		}
	}

	&--close {
		position: absolute;
		background: red;
		width: 65px;
		height: 65px;
		padding: 23px 25px;
		top: 0;
		right: 0;

		svg {
			width: 18px;
			height: 18px;
			use {
				fill: var(--theme-accent-color);
			}
		}

		&:focus,
		&:hover,
		&:active {
			cursor: pointer;

			svg {
				use {
					fill: var(--theme-accent-color-light);
				}
			}
		}
	}

	&--tray {
		min-width: 100%;

		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 100;

		transition: opacity 0.3s, transform 0.3s;

		display: flex;
		flex-flow: column nowrap;
		overflow: hidden;
		justify-content: space-between;

		padding: var(--padding-rythm-sm);

		opacity: 0;
		pointer-events: none;

		&.event-detail-active {
			opacity: 1;
			pointer-events: all;
		}

		&-close {
			position: absolute;
			top: var(--padding-rythm-sm);
			right: var(--padding-rythm-sm);
			z-index: 100;
			width: 30px;
			height: 30px;
			padding: 5px;
			background: white;
			svg {
				width: 100%;
				height: auto;
			}
		}

		&-content {
			color: black;
			background: white;

			&[data-venue-type="venue"] {
				--c-site-accent: #00adee;
			}
			&[data-venue-type="info_point"] {
				--c-site-accent: #fcaf17;
			}
			&[data-venue-type="street_art"] {
				--c-site-accent: #3b247d;
			}
			&[data-venue-type="festival_wall"] {
				--c-site-accent: #e61c6b;
			}

			&-inner {
				padding: var(--padding-rythm-sm);
				h2 {
					padding-right: 25%;
					margin-bottom: 1.2em;
				}
			}

			img {
				width: 100%;
				height: auto;
				display: block;
			}

			display: none;
			&.is-active {
				display: block;
			}
		}
	}
}

.gm-style {
	cursor: pointer;
}

@include from($bp-medium) {
	.map-overlay {
		&--tray {
			min-width: 0;
			max-width: 550px;
			&-content {
				&-inner {
					padding: var(--gutter);
				}
			}
			&-close {
				padding: 10px;
			}
		}
	}
}
