.l-site-container {
	&--body {
		position: relative;
		z-index: 2;
		min-height: 100vh;
	}
	&--background {
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		img {
			width: auto;
			height: 270vh;
			margin-left: -80vw;
		}
	}
}

@include from($bp-medium) {
	.l-site-container {
		&--background {
			img {
				width: 100%;
				height: auto;
				margin-left: 0;
			}
		}
	}
}
