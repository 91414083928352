$input-padding: $padding-rythm-sm / 2;
$input-main-color: $dark-grey;

.form-select {
	position: relative;
	width: 100%;
	color: $input-main-color;

	&--label {
		margin-bottom: $padding-rythm-sm / 2;
		width: 100%;
		font-weight: bold;
		color: var(--c-site-accent);
		font-size: var(--fs-body-lg);
		display: block;
		line-height: 1.3;
	}

	// Fallback styles for touch devices
	&--field {
		display: block;
		padding: $input-padding $input-padding $input-padding $input-padding;
		width: 100%;

		line-height: 1.3;
		font-weight: 100;

		background: none;

		border-radius: 0;
		border: 1px solid;

		color: inherit;
		box-shadow: none;
		outline: none;

		&.is-valid {
		}

		&.is-invalid {
			border-color: red;
			~ .form-select--error-messages {
				display: block;
			}
		}

		&:invalid {
			// Overide browser defaults. We use classes as below (for browser compat and UX reasons)
			outline: 0 none;
			box-shadow: none;
		}
		&:required {
		}
	}

	@import "../vendor/selectr";

	.selectr-selected {
		padding: $input-padding $input-padding $input-padding $input-padding;
		border-radius: 0;
		border: 1px solid;
		outline: 0;
	}

	.selectr-container.open .selectr-selected {
		border: 1px solid $input-main-color;
		border-bottom: none;
		border-radius: 0;
	}

	&--error-messages {
		width: 100%;
		&-single {
			margin-top: 12px;
		}
	}

	&--help {
		width: 100%;
		padding: 12px;
	}
}
