/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 7, 2019 */

@font-face {
	font-family: "heading_pro";
	src: url("../fonts/heading_pro_wide_black_italic-webfont.woff2")
			format("woff2"),
		url("../fonts/heading_pro_wide_black_italic-webfont.woff") format("woff");
	font-display: swap;
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "heading_pro_double";
	src: url("../fonts/HeadingProDouble-Book.woff2") format("woff2"),
		url("../fonts/HeadingProDouble-Book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "heading_pro";
	src: url("../fonts/heading_pro_wide_extrabold_italic-webfont.woff2")
			format("woff2"),
		url("../fonts/heading_pro_wide_extrabold_italic-webfont.woff")
			format("woff");
	font-display: swap;
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "heading_pro";
	src: url("../fonts/headingprowide-extrabold-webfont.woff2") format("woff2"),
		url("../fonts/headingprowide-extrabold-webfont.woff") format("woff");
	font-display: swap;
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "heading_pro";
	src: url("../fonts/headingprowide-regular-webfont.woff2") format("woff2"),
		url("../fonts/headingprowide-regular-webfont.woff") format("woff");
	font-display: swap;
	font-weight: 400;
	font-style: normal;
}
