body {
	font-size: var(--fs-body-md);
	font-family: $body-font;
	line-height: 1.4;
	font-weight: 100;
	color: $dark-grey;
	font-feature-settings: "pnum";
	font-feature-settings: "lnum";

	background: var(--c-site-accent);
	color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

h1 {
	font-size: $fs-h1-sm;
}

h2 {
	font-size: $fs-h2-sm;
}

.lede {
	font-size: 1.2em;
}

.section-title {
	font-weight: 800;
	text-transform: uppercase;
	color: $yellow;
	color: var(--c-site-accent);
	font-size: var(--fs-h3);
}

.richtext,
.rich-text {
	> * + * {
		margin-top: 0.4em;
	}
	> * + h1,
	> * + h2,
	> * + h3,
	> * + h4,
	> p + p {
		margin-top: 1em;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	h2:not([class]),
	h4:not([class]),
	h3:not([class]) {
		font-weight: bold;
		color: var(--c-site-accent);
	}

	h2:not([class]) {
		font-size: var(--fs-h2);
	}
	h3:not([class]) {
		font-size: var(--fs-h3);
	}
	h4:not([class]) {
		font-size: var(--fs-h4);
	}

	a:not([class]) {
		@include base-link;
		display: inline;
	}

	ul,
	ol {
		padding-left: 1.2em;
	}

	ul {
		li {
			list-style: disc;
		}
		li + li {
			margin-top: 0.5em;
		}
	}

	.responsive-object {
		position: relative;
		iframe,
		img {
			position: absolute;
			height: 100%;
			width: 100%;
		}
	}

	&__small {
		font-size: 0.8em;
	}
}

@include to($bp-medium) {
	.richtext-image.left {
		max-width: 50%;
	}
}

@include from($bp-medium) {
	h1 {
		font-size: $fs-h1;
	}
	h2 {
		font-size: $fs-h2;
	}

	.section-title {
		font-size: $fs-h2;
	}

	.richtext,
	.rich-text {
		> * + * {
			margin-top: 1em;
		}
		> * + h1,
		> * + h2,
		> * + h3,
		> * + h4 {
			margin-top: 1.6em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}
		h2:not([class]) {
			font-weight: bold;
			font-size: 32px; // TODO: Review typography font sizes
		}
		img {
			&.left {
				max-width: 50%;
			}
		}
	}
}
