/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-tiny: 400;
$bp-small: 768;
$bp-medium: 1024;
$bp-large: 1280;
$bp-very-large: 1400;

/**
 * Font definitions
 */
$defaultFontSize: 1;

$body-font: "heading_pro_double", sans-serif;
$heading-font: "heading_pro", sans-serif;

/**
 * Font Sizes
 */
$fs-h1: 66px;
$fs-h2: 45px;
$fs-h1-sm: 35px;
$fs-h2-sm: 18px;

/**
 * Transitions
 */
$base-anim-rythm: 300ms;
$transition-default: all $base-anim-rythm ease-in-out;

/**
 * Padding
 * Usage: padding: $padding-rythm*2
 */
$padding-rythm: 60px;
$padding-rythm-sm: 30px;

/**
 * Raw Colors
 */
$dark-grey: #1c1c1c;
$middle-grey: #808285; // #a7a9ac - put the similar colors you've replaced here
$light-grey: #efeff0; // #eaebec
$orange: #ff6f03;

$yellow: #faae17;
$pink: #e61c6b;
$blue: #00adee;
$purple: #321e6a;
$purple-light: #3b247d;

/**
 * z-index stack - where possible use DOM order or small hoists (eg z-index: 1 vs z-index: $zi-layer-4)
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 200;

/**
 * Misc
 */
$max-width: ($bp-medium * 1px);
$box-shadow: 0 2px 10px rgba(black, 0.2);
$mobile-header-height: 110px;

$heading-x-offset: -0.1em;

$border-radius: 5px;

:root {
	--fs-h1: 29px;
	--fs-h2: 22px;
	--fs-h3: 20px;
	--fs-h4: 16px;

	--fs-body-lg: 18px;
	--fs-body-md: 16px;
	--fs-body-sm: 14px;

	--padding-rythm-sm: 10px;
	--padding-rythm: 20px;
	--gutter: 15px;

	--ui-arrow-width: 90px;

	--viewport-height: 100vh;

	--c-site-accent: #00adee;
}

@include from($bp-tiny) {
	:root {
		--fs-h1: 38px;
		--fs-h2: 28px;
		--fs-h3: 20px;
		--fs-h4: 18px;
		--padding-rythm: 30px;
	}
}

@include from($bp-medium) {
	:root {
		--fs-h1: 70px;
		--fs-h2: 36px;
		--fs-body-lg: 20px;
		--fs-body-md: 18px;

		--padding-rythm-sm: 20px;
		--padding-rythm: 60px;
		--gutter: 40px;
	}
}

@include from($bp-very-large) {
	:root {
		--fs-h1: 88px;
		--fs-h2: 45px;
		--fs-h3: 22px;
	}
}
