@mixin block-base-sm() {
	background: white;
	padding: $padding-rythm-sm;
}

@mixin block-base() {
	background: white;
	padding: $padding-rythm;
}

@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1;
	font-family: $heading-font;
	margin: 0;
	font-weight: 800;
	text-transform: uppercase;
}

@mixin base-link() {
	transition: border-color 150ms ease-in;
	color: inherit;
	position: relative;
	border-bottom: 3px solid var(--c-site-accent);
	&:hover,
	&:focus,
	&:active {
		border-bottom-color: $dark-grey;
	}
}

@mixin base-cover {
	position: relative;
	background: white;
	color: $purple-light;
	padding: var(--padding-rythm-sm);
	padding-right: calc(var(--ui-arrow-width) + var(--padding-rythm-sm));
	&-title {
		@include base-heading;
		font-size: var(--fs-h2);
		margin-bottom: 0;
	}
	&-text {
		margin-top: 0.5em;
		font-size: var(--fs-body-lg);
	}
	&-arrow {
		position: absolute;
		top: 0;
		right: 0;
		background: $purple-light;
		width: var(--ui-arrow-width);
		height: 100%;
		&-label {
			display: none;
		}
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 18px;
			height: 30px;
			color: white;
		}
	}
}
