.program-card {
	position: relative;
	display: flex;

	&.is-hidden {
		display: none;
	}

	&--inner {
		transition: background 300ms ease-in-out;
		display: block;
		background: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: var(--padding-rythm-sm);

		&:hover {
		}
	}

	&--ticket,
	&--title,
	&--time,
	&--location {
		@include base-heading;
	}

	&--ticket,
	&--time,
	&--location {
		font-size: var(--fs-body-sm);
		display: block;
		color: var(--c-site-accent);
	}

	&--title {
		color: $purple;
		font-size: var(--fs-body-lg);
		display: inline-block;
		position: relative;
		margin-bottom: 0.5em;
		&:after {
			position: absolute;
			content: "";
			transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
			width: 100%;
			height: 2px;
			background: var(--theme-accent-color);
			bottom: -5px;
			left: 0;
			opacity: 0;
			transform: translateY(3px);
		}
	}

	&--ticket {
		margin-top: 1em;
	}
}

@include to($bp-medium) {
	.program-card {
		width: 100%;
		&--inner {
			flex-grow: 1;
		}
	}
}

@include from($bp-medium) {
	.program-card {
		position: relative;
		z-index: 1001;
		min-width: 325px;
		&--inner {
			position: relative;
			z-index: 2;
			flex: 1;
		}
	}
}
