.l-banner {
	overflow: hidden;
	min-height: 50px;
	&--background {
		display: block;
	}
}

@include from($bp-medium) {
	.l-banner {
		min-height: 150px;
	}
}
