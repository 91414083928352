.blockquote {
	padding-left: $padding-rythm-sm;
	border-left: 2px solid $middle-grey;
	margin: 0;
	&--body {

	}
	&--citation {
		font-style: italic;
	}
}

@include from($bp-small) {
	.blockquote {
		padding-left: $padding-rythm;
	}
}