.l-grid {
	&--item {
		+ .l-grid--item {
			margin-top: $padding-rythm-sm;
		}
	}
}

@include from($bp-medium) {
	.l-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: $padding-rythm-sm;
		&--item {
			+ .l-grid--item {
				margin-top: 0;
			}
		}
	}
}
