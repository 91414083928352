.selectr-container {
	position: relative;
}

.selectr-container.disabled * {
	pointer-events: none;
}

.selectr-container li {
	list-style: none;
}

.selectr-container .hidden-input {
	position: absolute !important;
	overflow: hidden !important;
	clip: rect(0px, 0px, 0px, 0px) !important;
	width: 1px !important;
	height: 1px !important;
	margin: -1px !important;
	padding: 0 !important;
	border: 0 none !important;
}

.selectr-selected {
	position: relative;
	z-index: 1;
	box-sizing: border-box;
	width: 100%;
	padding: 7px 28px 7px 14px;
	cursor: pointer;
	border: 1px solid #999;
	border-radius: 3px;
	background-color: #fff;
}

.selectr-selected::before {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 0;
	height: 0;
	content: '';
	-webkit-transform: rotate(0deg) translate3d(0px, -50%, 0px);
	transform: rotate(0deg) translate3d(0px, -50%, 0px);
	border-width: 4px 4px 0;
	border-style: solid;
	border-color: #6c7a86 transparent transparent;
}

.selectr-label {
	display: none;
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.selectr-placeholder {
	color: #6c7a86;
}

.selectr-tags {
	margin: 0;
	padding: 0;
	white-space: normal;
}

.has-selected .selectr-tags {
	margin: 0 0 -2px;
}

.selectr-tag {
	list-style: none;
	position: relative;
	float: left;
	padding: 2px 25px 2px 8px;
	margin: 0 2px 2px 0;
	cursor: default;
	color: #fff;
	border: medium none;
	border-radius: 10px;
	background: #acb7bf none repeat scroll 0 0;
}

.selectr-container.multiple.has-selected .selectr-selected {
	padding: 5px 28px 5px 5px;
}

.selectr-options-container {
	position: absolute;
	z-index: 10000;
	top: calc(100% - 1px);
	display: none;
	box-sizing: border-box;
	width: 100%;
	border-width: 0 1px 1px;
	border-style: solid;
	border-color: transparent #999 #999;
	border-radius: 0 0 3px 3px;
	background-color: #fff;
}

.selectr-container.open .selectr-options-container {
	display: block;
}

.selectr-input-container {
	position: relative;
	display: none;
}

.selectr-clear,
.selectr-tag-remove {
	position: absolute;
	top: 50%;
	right: 22px;
	width: 20px;
	height: 20px;
	padding: 0;
	cursor: pointer;
	-webkit-transform: translate3d(0px, -50%, 0px);
	transform: translate3d(0px, -50%, 0px);
	border: medium none;
	background-color: transparent;
}

.selectr-clear,
.selectr-selected .selectr-clear {
	display: none;
}

.has-selected .selectr-selected .selectr-clear {
	display: block;
}

.selectr-selected .selectr-tag-remove {
	right: 2px;
}

.selectr-clear::before,
.selectr-clear::after,
.selectr-tag-remove::before,
.selectr-tag-remove::after {
	position: absolute;
	top: 5px;
	left: 9px;
	width: 2px;
	height: 10px;
	content: ' ';
	background-color: #6c7a86;
}

.selectr-tag-remove::before,
.selectr-tag-remove::after {
	top: 4px;
	width: 3px;
	height: 12px;
	background-color: #fff;
}

.selectr-clear:before,
.selectr-tag-remove::before {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.selectr-clear:after,
.selectr-tag-remove::after {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.selectr-input-container.active,
.selectr-input-container.active .selectr-clear {
	display: block;
}

.selectr-input {
	top: 5px;
	left: 5px;
	box-sizing: border-box;
	width: calc(100% - 30px);
	margin: 10px 15px;
	padding: 7px 30px 7px 9px;
	border: 1px solid #999;
	border-radius: 3px;
}

.selectr-notice {
	display: none;
	box-sizing: border-box;
	width: 100%;
	padding: 8px 16px;
	border-top: 1px solid #999;
	border-radius: 0 0 3px 3px;
	background-color: #fff;
}

.selectr-container.notice .selectr-notice {
	display: block;
}

.selectr-container.notice .selectr-selected {
	border-radius: 3px 3px 0 0;
}

.selectr-options {
	position: relative;
	top: calc(100% + 2px);
	display: none;
	overflow: auto;
	max-height: 200px;
	margin: 0;
	padding: 0;
}

.selectr-container.open .selectr-options,
.selectr-container.open .selectr-input-container,
.selectr-container.notice .selectr-options-container {
	display: block;
}

.selectr-options li {
	position: relative;
	display: block;
	padding: 5px 20px;
	list-style: outside none none;
	cursor: pointer;
	font-weight: normal;
}

.selectr-options.optgroups > li.selectr-option {
	padding-left: 25px;
}

.selectr-optgroup {
	font-weight: bold;
	padding: 0;
}

.selectr-options li.selectr-optgroup--label {
	font-weight: bold;
	margin-top: 10px;
	padding: 5px 15px;
}

.selectr-options li.match span {
	text-decoration: underline;
}

.selectr-option.selected {
	background-color: #ddd;
}

.selectr-option.active {
	color: #fff;
	background-color: #5897fb;
}

.selectr-option.disabled {
	opacity: 0.4;
}

.selectr-option.excluded {
	display: none;
}

.selectr-options > li > * {
	pointer-events: none;
}

.selectr-container.open .selectr-selected {
	border-color: #999 #999 transparent #999;
	border-radius: 3px 3px 0 0;
}

.selectr-container.open .selectr-selected::after {
	-webkit-transform: rotate(180deg) translate3d(0px, 50%, 0px);
	transform: rotate(180deg) translate3d(0px, 50%, 0px);
}

.selectr-container.disabled {
	opacity: .6;
}

.selectr-empty,
.has-selected .selectr-placeholder {
	display: none;
}

.has-selected .selectr-label {
	display: block;
}

/* TAGGABLE */

.taggable .selectr-selected {
	padding: 4px 28px 4px 4px;
}

.taggable .selectr-selected::after {
	display: table;
	content: " ";
	clear: both;
}
.taggable .selectr-label {
		width: auto;
}
.taggable .selectr-tags {
		float: left;
		display: block;
}
.taggable .selectr-placeholder {
	display: none;
}

.input-tag {
	float: left;
	max-width: 200px;
	min-width: 90px;
	width: 90px;
}
.selectr-tag-input {
	border: medium none;
	padding: 3px 10px;
	width: 100%;
}
.selectr-tag-input:focus {
	outline: 0;
}
.selectr-input-container.loading::after {
	position: absolute;
	top: 50%;
	right: 20px;
	width: 20px;
	height: 20px;
	content: '';
	-webkit-transform: translate3d(0px, -50%, 0px);
	transform: translate3d(0px, -50%, 0px);
	-webkit-transform-origin: 50% 0 0;
	transform-origin: 50% 0 0;
	-webkit-animation: 500ms linear 0s normal forwards infinite running spin;
	animation: 500ms linear 0s normal forwards infinite running spin;
	border-width: 3px;
	border-style: solid;
	border-color: #aaa #ddd #ddd;
	border-radius: 50%;
}


@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg) translate3d(0px, -50%, 0px);
		transform: rotate(0deg) translate3d(0px, -50%, 0px);
	}
	100% {
		-webkit-transform: rotate(360deg) translate3d(0px, -50%, 0px);
		transform: rotate(360deg) translate3d(0px, -50%, 0px);
	}
}

.selectr-container.open.inverted .selectr-selected {
	border-color: transparent #999 #999;
	border-radius: 0 0 3px 3px;
}

.selectr-container.inverted .selectr-options-container {
	border-width: 1px 1px 0;
	border-color: #999 #999 transparent;
	border-radius: 3px 3px 0 0;
	background-color: #fff;
}

.selectr-container.inverted .selectr-options-container {
	top: auto;
	bottom: calc(100% - 1px);
}
