$input-text-active-offset: 8px;
$input-padding: $padding-rythm-sm / 2;
$input-main-color: $dark-grey;

.form-textarea {
	margin-bottom: $padding-rythm-sm;
	max-width: 100%;
	width: 100%;
	padding: $padding-rythm-sm 0;
}

.form-input {
	position: relative;
	width: 100%;
	color: $input-main-color;

	&--field,
	&--label {
		font-size: var(--fs-body-lg);
		display: block;

		line-height: 1.3;
		font-weight: 100;
		background: none;
	}
	&--field {
		border: 0 none;
		padding: 15px;
		width: 100%;
		appearance: none;
		border-radius: 0;
		border: 1px solid $purple;

		&:focus,
		&[value]:not([value=""]),
		&.is-focused {
			&.is-valid,
			&.is-valid + .form-input--label {
			}

			&.is-invalid,
			&.is-invalid + .form-input--label {
				border-color: red;
			}
		}

		&.is-valid,
		&.is-valid + .form-input--label {
		}

		&.is-invalid,
		&.is-invalid + .form-input--label {
			border-color: red;
		}
		&.is-invalid {
			~ .form-input--error-messages {
				display: block;
			}
		}
		&:invalid {
			// Overide browser defaults. We use classes as below (for browser compat and UX reasons)
			outline: 0 none;
			box-shadow: none;
		}
		&:required {
			+ .form-input--label:after {
				content: "*";
				display: inline;

				color: red;
			}
		}

		&[type="checkbox"],
		&[type="radio"] {
			width: auto;
		}
	}
	&--label {
		margin-bottom: $padding-rythm-sm / 2;
		width: 100%;
		font-weight: bold;
		color: var(--c-site-accent);
	}

	&--error-messages {
		width: 100%;
		&-single {
			margin-top: 12px;
		}
	}

	&--help-text {
		width: 100%;
		font-size: var(--fs-body-sm);
		margin-top: $padding-rythm-sm / 2;
	}

	+ .form-input {
		margin-top: $padding-rythm-sm / 2;
	}

	// &__file {
	// 	.form-input {
	// 		&--field {
	// 			display: none;
	// 		}
	// 		&--fake-input {
	// 			width: 100%;
	// 			padding: 15px;
	// 			padding-right: 145px;
	// 			text-transform: uppercase;
	// 			display: inline-block;
	// 			background: white;
	// 			border: 1px solid $purple;
	// 			font-weight: 800;
	// 			color: $purple;
	// 			line-height: 1;
	// 			word-break: break-all;
	//
	// 			position: relative;
	//
	// 			&:hover {
	// 				cursor: pointer;
	// 			}
	//
	// 			&:after {
	// 				content: "Upload";
	// 				position: absolute;
	// 				right: 0;
	// 				top: 0;
	// 				height: 100%;
	// 				background: $purple;
	// 				color: white;
	// 				padding: 15px;
	// 				display: flex;
	// 				align-items: center;
	// 			}
	// 		}
	// 	}
	// }
}

@include from($bp-medium) {
	.form-input {
		+ .form-input {
			margin-top: $padding-rythm-sm / 2;
		}

		&--field {
			padding: $padding-rythm-sm;
		}

		// &__file {
		// 	padding-right: $padding-rythm-sm * 7;
		// 	.form-input {
		// 		&--fake-input {
		// 			min-width: 300px;
		// 			padding: $padding-rythm-sm - 5px $padding-rythm-sm;
		// 			padding-right: $padding-rythm-sm * 7;
		// 			&:after {
		// 				padding: $padding-rythm-sm - 5px $padding-rythm-sm;
		// 			}
		// 		}
		// 	}
		// }
	}
}
