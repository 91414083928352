.social-bar {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	&--item {
		margin-right: 20px;
		&:nth-child(2) {
			.social-bar {
				&--link {
					background-position: -100% 0;
				}
			}
		}
		&:nth-child(3) {
			.social-bar {
				&--link {
					background-position: -200% 0;
				}
			}
		}
	}
	&--link {
		display: block;
		width: 40px;
		height: 40px;
		// border: 3px solid $dark-grey;
		color: white;
		padding: 5px;
		svg {
			width: 100%;
			height: auto;
			display: block;
		}
	}
}
