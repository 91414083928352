.btn {
	@include base-link;
	background: none;
	border: 0 none;
	padding: 0;

	span {
		transition: border-color 150ms ease-in;
		border-bottom: 3px solid var(--c-site-accent);
	}

	&:hover,
	&:focus,
	&:active {
		span {
			border-bottom-color: $dark-grey;
		}
	}

	&--arrow {
		display: inline-block;
		margin-left: 16px;
		margin-bottom: -2px;
		height: 16px;
		width: auto;
	}
}
