.captioned-media {
	&--asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&--caption {
		font-size: 14px;
		padding: 15px;
	}
}
