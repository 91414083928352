.l-site-content {
	padding: 0 var(--padding-rythm);
	&--section {
		&-header {
			padding-top: calc(3 * var(--padding-rythm));
			&-title {
				@include base-heading;
				font-size: var(--fs-h1);
				margin-bottom: var(--padding-rythm);
			}
		}
	}
	&--section + &--section {
		padding-top: calc(2 * var(--padding-rythm));
	}
}

@include from($bp-medium) {
	.l-site-content {
		.l-banner {
			margin-top: var(--padding-rythm);
			+ .l-streamfield {
				margin-top: var(--padding-rythm);
			}
		}
		.l-page--body__no-banner {
			margin-top: calc(2.3 * var(--padding-rythm));
			&.l-page--body__hidden-title {
				margin-top: var(--padding-rythm);
			}
		}
	}
}
