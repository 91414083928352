.l-navigation {
	&--item,
	&--super-item {
		display: block;
		line-height: 1;
		&-link {
			@include base-heading;
			width: auto;
		}
	}
	&--super-item-link {
		svg {
			width: 8px;
			height: 13px;
			color: var(--c-site-accent);
		}
	}
}

@include to($bp-medium) {
	.l-navigation {
		transition: opacity 400ms ease-in-out;
		height: 100vh;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		opacity: 0;
		pointer-events: none;
		overflow-y: scroll;
		background: $purple;
		padding: var(--padding-rythm);
		padding-top: calc(var(--padding-rythm) * 2 + 47px);

		&.is-active {
			pointer-events: all;
			opacity: 1;
		}

		&--item {
			position: relative;
			&__has-submenu {
				margin-top: var(--padding-rythm);
				.submenu--links-single {
					font-size: 1em !important;
				}
			}
		}

		&--super-item-link,
		.submenu--links-single,
		&--item-link {
			@include base-heading;
			font-size: var(--fs-h2);
			margin-bottom: calc(var(--padding-rythm) / 2);
		}

		&--super {
			margin-bottom: var(--padding-rythm);
		}

		.orientation-changing & {
			transition: none;
		}
	}
}

@include from($bp-medium) {
	.l-navigation {
		flex: 1;

		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		flex-wrap: wrap;

		&--super {
			flex: 1;
			width: 100%;
			flex-basis: 100%;

			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			&-item-link {
				font-size: 0.8em;
			}
		}

		&--super-item,
		&--item {
			&-link {
				transition: border-color 150ms ease-in;
				position: relative;
				border-bottom: 3px solid transparent;
				&:hover,
				&:focus,
				&:active {
					border-bottom-color: var(--c-site-accent);
				}
			}
		}

		&--item {
			position: relative;
			margin: 0;

			.submenu {
				transition: opacity 200ms ease-in-out;
				position: absolute;
				top: 100%;
				right: 0;
				left: auto;
				min-width: 23ch;

				opacity: 0;
				pointer-events: none;
				&:hover,
				&:active,
				&:focus {
					.submenu {
						opacity: 1;
						pointer-events: all;
					}
				}
			}

			&:hover,
			&:active,
			&:focus {
				.submenu {
					opacity: 1;
					pointer-events: all;
				}
				&:before {
					opacity: 1;
				}
			}

			&__has-submenu {
				position: relative;
				height: auto;
				&:before {
					transition: opacity 200ms ease-in-out;
					opacity: 0;
					position: absolute;
					top: 100%;
					right: 50%;
					margin-right: calc(var(--padding-rythm-sm) * -1);
					content: "";
					width: 0;
					height: 0;
					border-left: var(--padding-rythm-sm) solid transparent;
					border-right: var(--padding-rythm-sm) solid transparent;
					border-bottom: var(--padding-rythm-sm) solid white;
				}
			}
		}

		&--item + &--item {
			margin-left: var(--padding-rythm-sm);
		}
	}
}
