.l-streamfield {
	color: black;
	> * + * {
		margin-top: var(--padding-rythm);
	}
}

@include to($bp-medium) {
	.l-streamfield {
		padding: var(--padding-rythm);
		.l-grid {
			margin-left: calc(-1 * (var(--padding-rythm) / 2));
			margin-right: calc(-1 * (var(--padding-rythm) / 2));
		}
		.captioned-media {
			margin-left: calc(-1 * (var(--padding-rythm) / 2));
			margin-right: calc(-1 * (var(--padding-rythm) / 2));
			&--caption {
				padding-left: calc(var(--padding-rythm) / 2);
				padding-right: calc(var(--padding-rythm) / 2);
			}
			&:first-child {
				margin-top: calc(-1 * (var(--padding-rythm) / 2));
			}
		}
		.section-title {
			padding-left: calc(var(--padding-rythm) / 2);
			padding-right: calc(var(--padding-rythm) / 2);
			margin-left: calc(-1 * (var(--padding-rythm) / 2));
			margin-right: calc(-1 * (var(--padding-rythm) / 2));
		}
	}
}

@include from($bp-medium) {
	.l-streamfield {
		display: grid;
		grid-template-columns:
			1fr
			min(65ch, 100%)
			1fr;
		> *:not(.captioned-media) {
			grid-column: 2;
			padding-left: var(--padding-rythm-sm);
		}
		.meta-list + .rich-text {
			margin-top: calc(var(--padding-rythm) / 2);
		}
		.captioned-media {
			width: 100%;
			grid-column: 1 / 4;
			&__fit-content {
				grid-column: 2 / 3;
			}
		}
		.l-page--body-title,
		.rich-text {
			background: white;
		}
		.l-page--body-title {
			grid-column: 2 / 4;
			margin-top: -1.1em;
			padding: var(--padding-rythm-sm);
			padding-top: calc(var(--padding-rythm-sm) / 2);
			padding-bottom: 0;
		}

		> .rich-text {
			padding: 0 var(--padding-rythm-sm);
		}
	}
}
