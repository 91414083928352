$size: 20px;

.radio-link {
	@include base-heading;
	display: block;
	padding: var(--padding-rythm-sm);
	background: white;
	color: $purple;
	position: relative;
	span {
		display: block;
		font-size: var(--fs-body-sm);
	}

	&.is-active {
		color: white;
		background: var(--c-site-accent);
	}
}
@include to($bp-medium) {
	.radio-link-list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: var(--padding-rythm-sm);
	}
}

@include from($bp-medium) {
	.radio-link {
	}

	.radio-link-list {
		display: flex;
	}
}
