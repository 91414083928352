.l-page {
	&--body {
		background: white;
		&-title {
			@include base-heading;
			font-size: var(--fs-h2);
			color: var(--c-site-accent);
		}
	}
}

@include from($bp-medium) {
	.l-page {
		&--body {
			padding-bottom: calc(var(--padding-rythm) * 2);
			&-title {
				font-size: var(--fs-h1);
			}
		}
	}
}
