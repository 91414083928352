.l-signup {
	.form-input {
		max-width: 600px;
		&--field {
			background: white;
			border: 3px solid white;
			color: $purple-light;
			&:active,
			&:focus {
				border-color: var(--c-site-accent);
			}
		}
	}
	.form-error,
	.btn {
		margin-top: var(--padding-rythm-sm);
	}
}
