.submenu {
	&--links {
	}
}

@include from($bp-medium) {
	.submenu {
		transition: transform 400ms ease-in-out;
		height: 100vh;
		width: 100%;

		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;

		z-index: 10;

		.orientation-changing & {
			transition: none;
		}

		&.is-active {
			transform: translateX(0);
		}

		&--links {
			background: white;
			&-single + &-single {
				margin-top: var(--padding-rythm-sm);
			}
		}
	}
}

@include from($bp-medium) {
	.submenu {
		padding-top: var(--padding-rythm-sm);
		height: auto;
		&--links {
			padding: var(--padding-rythm-sm);
			a {
				@include base-heading;
				transition: border-color 150ms ease-in;
				position: relative;
				border-bottom: 3px solid transparent;
				color: $purple;
				&:hover,
				&:focus,
				&:active {
					border-bottom-color: var(--c-site-accent);
				}
			}
		}
	}
}
