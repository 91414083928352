.l-site-header {
	position: relative;
	z-index: 100;
	margin-top: var(--gutter);
	padding: var(--padding-rythm);
	padding-top: 0;
	&--logo {
		position: relative;
		display: block;
		height: 47px;
		width: 163px;
		img {
			width: auto;
			height: 100%;
		}
		&-addition {
			@include base-heading;
			position: absolute;
			bottom: 0;
			right: 0;
			bottom: -11px;
			right: 0px;
			font-size: 17px;
		}
	}

	&--menu-trigger {
		$mt: &;
		position: absolute;
		top: 0;
		right: var(--padding-rythm);
		z-index: 3;
		border-bottom: 3px solid var(--c-site-accent);

		&-close,
		&-open {
			font-size: 0;
			letter-spacing: 0;
			white-space: nowrap;

			span {
				font-size: var(--fs-body-sm);
				display: inline;

				transition: opacity 300ms ease-in-out;
				transform: translateX(10px);
				@for $i from 1 through 5 {
					&:nth-child(#{$i}) {
						transition-delay: ($i * 100ms);
					}
				}
			}
		}

		&-close {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			span {
				opacity: 0;
			}
		}

		&.is-active {
			#{$mt}-open {
				span {
					opacity: 0;
				}
			}
			#{$mt}-close {
				span {
					opacity: 1;
				}
			}
		}
	}

	.l-navigation {
		z-index: 2;
	}
}

@include from($bp-medium) {
	.l-site-header {
		display: flex;
		justify-content: space-between;
		padding: 0 var(--padding-rythm);

		&--logo {
			width: 277px;
			height: auto;
			img {
				width: 100%;
				height: auto;
			}
			&-addition {
				bottom: -18px;
				right: 0px;
				font-size: 30px;
			}
		}

		&--menu-trigger {
			display: none;
		}
	}
}
