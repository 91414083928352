.accordion {
	&--toggle {
		display: block;
		position: relative;
		padding: $padding-rythm-sm / 2;

		border: 1px solid $dark-grey;

		&:after {
			content: "";
			position: absolute;
			right: $padding-rythm-sm;
			top: 50%;
			width: 8px;
			height: 8px;
			transform: rotate(135deg) translateY(7px);
			border-right: 1px solid $dark-grey;
			border-top: 1px solid $dark-grey;
		}
	}
	&--content {
		display: none;

		padding: $padding-rythm-sm / 2;
		border: 1px solid $dark-grey;
		border-top: 0 none;
	}
	&--message {
		margin-top: 10px;
		color: red;
	}
	&.is-active &--content {
		display: block;
	}
	&.is-active &--toggle:after {
		transform: rotate(-45deg) translateY(0px);
	}
}
