$control-size: 1em;

.tabs {
	&--title {
		font-size: $fs-h2-sm;
		margin-bottom: $padding-rythm-sm;
	}
	&--navigation {
		display: flex;
		&-single {
			padding: $padding-rythm-sm / 2;
			padding-left: $padding-rythm-sm * 2;
			border: 1px solid transparent;
			border-bottom: 0 none;
			position: relative;

			&:before,
			&:after {
				content: "";
				width: $control-size;
				height: $control-size;
				position: absolute;
				left: $padding-rythm-sm / 2;
				top: 50%; // Adjusted for line height
				margin-top: -#{$control-size/2};
			}
			&:before {
				border-radius: 100%;
				border: 1px solid $input-main-color;
			}
			&:after {
				transition: transform 200ms ease-in-out;
				background: $input-main-color;
				transform: scale(0);
				border-radius: 100%;
			}
			&.is-active {
				border-color: $input-main-color;
				&:after {
					transform: scale(0.5);
				}
			}
		}
	}
	&--panel {
		display: none;
		&.is-active {
			display: block;
		}
	}
}
