.l-program {
	display: none;
	&.is-active {
		display: block;
	}
	&--group {
		.program-card + .program-card {
			margin-top: var(--padding-rythm-sm);
		}
		&-note {
			margin-bottom: var(--padding-rythm-sm);
		}
	}
	&--title {
		@include base-heading;
		font-size: var(--fs-h2);
		margin-bottom: 0.5em;
		margin-top: calc(var(--padding-rythm-sm) * 2);
		&:first-child {
			margin-top: 0;
		}
	}
}

@include from($bp-small) {
	.l-program {
		&--group {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: $padding-rythm / 2;
			.program-card + .program-card {
				margin-top: 0;
			}
			.program-card {
				margin-right: var(--padding-rythm-sm);
				margin-bottom: var(--padding-rythm-sm);
			}

			&-note {
				display: block;
				width: 100%;
			}
		}
	}
}
