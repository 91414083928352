.l-form-group {
	.btn,
	.form-input,
	.rich-text,
	.tabs,
	.form-message,
	.form-select,
	.accordion,
	.form-input,
	.form-boolean-group,
	.l-form-group--errors,
	.form-boolean {
		+ .btn,
		+ .form-input,
		+ .form-boolean-group,
		+ .form-message,
		+ .form-select,
		+ .accordion,
		+ .rich-text,
		+ .tabs,
		+ .form-input,
		+ .l-form-group--errors,
		+ .form-boolean {
			margin-top: $padding-rythm-sm * 1.5;
		}
	}

	.form-boolean-group {
		.form-boolean + .form-boolean {
			margin-top: $padding-rythm-sm / 2;
		}
	}

	.form-footer {
		margin-top: $padding-rythm;
	}

	&--errors {
		color: red;
	}
}

@include from($bp-large) {
}
