.l-site-footer {
	padding: var(--padding-rythm);
	padding-top: calc(2 * var(--padding-rythm));

	&--social,
	&--sponsors {
		margin-bottom: var(--padding-rythm);
		&-label {
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: $padding-rythm-sm/2;
		}
	}

	&--social {
		&-links {
			a {
				@include base-link;
				@include base-heading;
				display: inline-block;
				+ a {
					margin-left: $padding-rythm-sm;
				}
			}
		}
	}

	// TODO: move to it;s own component
	&--sponsors {
		&-logos {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin-left: -15px;
			img {
				display: inline-block;
				width: 30%;
				height: auto;
				margin: 15px 5%;
				&.landscape {
					width: 40%;
				}
			}
		}
	}
	&--signoff {
		font-size: 0.8em;
	}
}

@include from($bp-small) {
	.l-site-footer {
		&--sponsors {
			&-logos {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-start;
				margin-left: -$padding-rythm-sm;
				img {
					display: inline-block;
					max-width: none;
					width: auto;
					height: 85px;
					margin: 0;
					margin-left: $padding-rythm-sm;
					&.landscape {
						width: auto;
						height: 60px;
					}
					&.unco {
						width: auto;
						height: 20px;
					}
					+ img {
						margin-top: 0;
					}
				}
			}
		}
	}
}
