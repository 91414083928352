.card {
	display: block;
	position: relative;

	&--cover {
		@include base-cover;
	}
}

@include to($bp-medium) {
	.card {
		&--cover {
			padding-right: var(--padding-rythm-sm);
			&-arrow {
				display: none;
			}
			&-text {
				font-size: var(--fs-body-sm);
			}
		}
	}
	.card-list {
		.card + .card {
			margin-top: var(--padding-rythm);
		}
	}
}
@include from($bp-medium) {
	.card-list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: var(--gutter);

		padding-bottom: calc(var(--padding-rythm) * 2);
		.card {
			&:nth-child(even) {
				transform: translateY(calc(var(--padding-rythm) * 2));
			}
		}
	}

	a.card {
		.card--cover-title {
			transition: text-decoration-color 150ms ease-in-out;
			text-decoration: underline;
			text-decoration-width: 6px;
			text-decoration-color: white;
		}
		&:hover,
		&:active {
			.card--cover-title {
				text-decoration: underline;
				text-decoration-width: 6px;
				text-decoration-color: var(--c-site-accent);
			}
		}
	}
}
