.content-cta {
	&--copy {
		@include base-cover;
	}
	&--media {
		picture {
			height: 100%;
			display: block;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

@include to($bp-medium) {
	.content-cta {
		&--copy-text {
			font-size: var(--fs-body-sm);
		}
	}
}

@include from($bp-medium) {
	.content-cta {
		$c: &;
		position: relative;
		&--cover {
			margin: auto 0;
		}
		&--copy {
			position: absolute;
			width: 50%;
			top: 0;
			left: 0;
			padding-bottom: calc(var(--padding-rythm-sm) + var(--ui-arrow-width));
		}
		&__small-image {
			display: grid;
			grid-template-columns: 1fr 1fr;
			#{$c}--cover {
				margin: auto 0;
			}
			#{$c}--copy {
				position: static;
				width: 100%;
				padding-right: var(--padding-rythm-sm);
				&-arrow {
					display: flex;
					align-items: center;
					justify-content: center;
					bottom: 0;
					top: auto;
					width: 50%;
					height: var(--ui-arrow-width);
					&-label {
						color: white;
						display: block;
						@include base-heading;
						font-size: var(--fs-body-lg);
					}
					svg {
						right: 20px;
						left: auto;
					}
				}
			}
		}
	}
}
