.coloured-text {
	display: block;
	p {
		font-size: 29px;
		text-transform: uppercase;
		word-spacing: 5px;
		font-weight: bold;
		display: inline-block;
		margin: 0;
		margin-right: 25px;
		+ p {
			margin-top: 0;
		}
	}
}
