.meta-list {
	&--title {
		@include base-heading;
		text-transform: uppercase;
		font-size: var(--fs-body-sm);
		color: var(--c-site-accent);
		margin-top: 1.5em;
		&:first-child {
			margin-top: 0;
		}
	}
	&--detail {
		@include base-heading;
		color: $purple;
		margin: 0;
		margin-top: 0.5em;
		margin-bottom: var(--padding-rythm-sm);

		* + * {
			margin-top: 0.5em;
		}

		a {
			@include base-link;
			display: inline-block;
			width: auto;
			padding-bottom: 3px;
		}
	}
}

@include from($bp-medium) {
	.meta-list {
		&--detail {
			a {
				+ a {
					margin-left: 1em;
				}
			}
		}
	}
}
