.l-home-fold {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: calc(var(--padding-rythm) * 2) 0;

	&--tagline,
	&--summary {
		@include base-heading;
		margin-left: $heading-x-offset;
	}
	&--tagline {
		font-size: var(--fs-h1);
	}

	&--summary {
		margin-top: var(--padding-rythm);
		font-size: var(--fs-body-sm);
	}

	.btn-group {
		margin-top: var(--padding-rythm);
	}
}

@include from($bp-medium) {
	.l-home-fold {
		&--summary {
			margin-top: 0.5em;
			font-size: var(--fs-body-lg);
		}
	}
}
