.l-image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: $padding-rythm-sm;
  &--item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      opacity: 0.7;
      height: auto;
      width: 100%;
    }
  }
}

@include from($bp-medium) {
  .l-image-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
};
